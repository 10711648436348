@use "../../global" as *;

.c-col {
	display: flex;
	flex-wrap: wrap;
}

@include responsive using ($prefix) {
	.#{$prefix}c-col {
    &__auto {
      flex: 0 1 auto;
    }
    &__fixed {
      flex: 0 0 auto; 
    }
    &__1 {
      width: 8.33%;
    }
    &__2 {
      width: 16.66%;
    }
    &__3 {
      width: 25%;
    }
    &__4 {
      width: 33.33%;
    }
    &__5 {
      width: 41.66%;
    }
    &__6 {
      width: 50%;
    }
    &__7 {
      width: 58.33%;
    }
    &__8 {
      width: 66.66%;
    }
    &__9 {
      width: 75%;
    }
    &__10 {
      width: 83.33%;
    }
    &__11 {
      width: 91.66%;
    }
    &__12 {
      width: 100%;
    }
    &__1of5 {
      width: 20%;
    }
    &__1of7 {
      width: 14.285%;
    }
	}
}
@use "../../global" as *;

.c-button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  width: 100%;
  padding: 1rem;
  font-weight: 400;
  @include font-size(normal);
  color: color('gray');
  transition: all 0.2s ease;
  &__icon{
      display: inline-block;
      width: 28px;
      transition: all 0.5s ease;
  }
  &--01 {
    background-color: color('lightblue');
    border-radius: 2px;
    color: color('white');
    z-index: 0;
    &::before {
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height:100%;
      background-color: color('red');
      transition: transform .6s cubic-bezier(0.8, 0, 0.2, 1) 0s;
      transform: scale(0, 1);
      transform-origin: right top;
      content:"";
      z-index: 1;
    }
    & span {
      position: relative;
      z-index: 2;
    }
    @include media(hover) {
      &:hover,
      &:focus{
        &::before {
          transform-origin:left top;
          transform:scale(1, 1);
        }
      }
    }
  }
}
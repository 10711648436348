* {
  box-sizing: border-box;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul, li {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  line-height: 0;
}

html {
  font-size: 16px;
}

body {
  letter-spacing: 0.1em;
  line-height: 1.5;
  font-family: "Noto Sans JP", sans-serif;
  font-kerning: normal;
  font-feature-settings: "palt";
  font-size: 1rem;
  font-weight: 300;
  color: #000000;
  background-color: #ffffff;
}
@media screen and (max-width: 1024px) {
  body {
    font-size: 0.875rem;
  }
}
@media screen and (max-width: 599px) {
  body {
    font-size: 0.875rem;
  }
}

body.is-fixscroll {
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100vw;
  height: 100vh;
  z-index: -999;
}

img,
object,
svg,
iframe {
  display: block;
  max-width: 100%;
  width: auto;
  height: auto;
  vertical-align: middle;
}

img[src*=".svg"] {
  width: 100%;
}

ul,
ol {
  list-style-position: inside;
}

::placeholder {
  color: #a0a0a0;
}

.l-container {
  position: relative;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.l-header {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.l-main {
  position: relative;
  width: 100%;
  min-height: 100vh;
  margin-right: auto;
  margin-left: auto;
}

.l-main--noespacer {
  padding-bottom: 0;
}
@media screen and (max-width: 1024px) {
  .l-main--noespacer {
    padding-bottom: 0;
  }
}

.l-footer {
  position: relative;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@keyframes fade-in {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-up {
  0% {
    display: none;
    opacity: 0;
    transform: translateY(100px);
  }
  1% {
    display: block;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fade-down {
  0% {
    display: none;
    opacity: 0;
    transform: translateY(-100px);
  }
  1% {
    display: block;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fade-right {
  0% {
    display: none;
    opacity: 0;
    transform: translateX(100px);
  }
  1% {
    display: block;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fade-left {
  0% {
    display: none;
    opacity: 0;
    transform: translateX(-100px);
  }
  1% {
    display: block;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.c-fade-in,
.c-fade-in-first,
.c-fade-up,
.c-fade-up-first,
.c-fade-down,
.c-fade-down-first,
.c-fade-right,
.c-fade-right-first,
.c-fade-left,
.c-fade-left-first {
  opacity: 0;
}

.c-fade-in.is-show,
.c-fade-in-first,
.c-fade-up.is-show,
.c-fade-up-first,
.c-fade-down.is-show,
.c-fade-down-first,
.c-fade-right.is-show,
.c-fade-right-first,
.c-fade-left.is-show,
.c-fade-left-first {
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

.c-fade-in.is-show,
.c-fade-up.is-show,
.c-fade-down.is-show,
.c-fade-right.is-show,
.c-fade-left.is-show {
  animation-duration: 0.4s;
}

.c-fade-in-first {
  animation-name: fade-in;
  animation-duration: 1s;
}

.c-fade-up-first {
  animation-name: fade-up;
}

.c-fade-down-first {
  animation-name: fade-down;
}

.c-fade-right-first {
  animation-name: fade-right;
}

.c-fade-left-first {
  animation-name: fade-left;
}

.c-fade-in.is-show {
  animation-name: fade-in;
}

.c-fade-up.is-show {
  animation-name: fade-up;
}

.c-fade-right.is-show {
  animation-name: fade-right;
}

.c-fade-left.is-show {
  animation-name: fade-left;
}

.c-fade-down.is-show {
  animation-name: fade-down;
}

.c-anime-duration-02 {
  animation-duration: 0.2s;
}

.c-anime-duration-04 {
  animation-duration: 0.4s;
}

.c-anime-duration-06 {
  animation-duration: 0.6s;
}

.c-anime-duration-08 {
  animation-duration: 0.8s;
}

.c-anime-duration-10 {
  animation-duration: 1s;
}

.c-anime-duration-12 {
  animation-duration: 1.2s;
}

.c-anime-duration-14 {
  animation-duration: 1.4s;
}

.c-anime-duration-16 {
  animation-duration: 1.6s;
}

.c-anime-duration-18 {
  animation-duration: 1.8s;
}

.c-anime-duration-20 {
  animation-duration: 2s;
}

.c-anime-delay-none {
  animation-delay: 0;
}

.c-anime-delay-02 {
  animation-delay: 0.2s;
}

.c-anime-delay-04 {
  animation-delay: 0.4s;
}

.c-anime-delay-06 {
  animation-delay: 0.6s;
}

.c-anime-delay-08 {
  animation-delay: 0.8s;
}

.c-anime-delay-10 {
  animation-delay: 1s;
}

.c-anime-delay-12 {
  animation-delay: 1.2s;
}

.c-anime-delay-14 {
  animation-delay: 1.4s;
}

.c-anime-delay-16 {
  animation-delay: 1.6s;
}

.c-anime-delay-18 {
  animation-delay: 1.8s;
}

.c-anime-delay-20 {
  animation-delay: 2s;
}

.c-anime-delay-22 {
  animation-delay: 2.2s;
}

.c-anime-delay-24 {
  animation-delay: 2.4s;
}

.c-anime-delay-26 {
  animation-delay: 2.6s;
}

.c-anime-delay-28 {
  animation-delay: 2.8s;
}

.c-anime-delay-30 {
  animation-delay: 3s;
}

@media screen and (max-width: 1024px) {
  .md\:c-anime-duration-02 {
    animation-duration: 0.2s;
  }
  .md\:c-anime-duration-04 {
    animation-duration: 0.4s;
  }
  .md\:c-anime-duration-06 {
    animation-duration: 0.6s;
  }
  .md\:c-anime-duration-08 {
    animation-duration: 0.8s;
  }
  .md\:c-anime-duration-10 {
    animation-duration: 1s;
  }
  .md\:c-anime-duration-12 {
    animation-duration: 1.2s;
  }
  .md\:c-anime-duration-14 {
    animation-duration: 1.4s;
  }
  .md\:c-anime-duration-16 {
    animation-duration: 1.6s;
  }
  .md\:c-anime-duration-18 {
    animation-duration: 1.8s;
  }
  .md\:c-anime-duration-20 {
    animation-duration: 2s;
  }
  .md\:c-anime-delay-none {
    animation-delay: 0;
  }
  .md\:c-anime-delay-02 {
    animation-delay: 0.2s;
  }
  .md\:c-anime-delay-04 {
    animation-delay: 0.4s;
  }
  .md\:c-anime-delay-06 {
    animation-delay: 0.6s;
  }
  .md\:c-anime-delay-08 {
    animation-delay: 0.8s;
  }
  .md\:c-anime-delay-10 {
    animation-delay: 1s;
  }
  .md\:c-anime-delay-12 {
    animation-delay: 1.2s;
  }
  .md\:c-anime-delay-14 {
    animation-delay: 1.4s;
  }
  .md\:c-anime-delay-16 {
    animation-delay: 1.6s;
  }
  .md\:c-anime-delay-18 {
    animation-delay: 1.8s;
  }
  .md\:c-anime-delay-20 {
    animation-delay: 2s;
  }
  .md\:c-anime-delay-22 {
    animation-delay: 2.2s;
  }
  .md\:c-anime-delay-24 {
    animation-delay: 2.4s;
  }
  .md\:c-anime-delay-26 {
    animation-delay: 2.6s;
  }
  .md\:c-anime-delay-28 {
    animation-delay: 2.8s;
  }
  .md\:c-anime-delay-30 {
    animation-delay: 3s;
  }
}
@media screen and (max-width: 599px) {
  .sm\:c-anime-duration-02 {
    animation-duration: 0.2s;
  }
  .sm\:c-anime-duration-04 {
    animation-duration: 0.4s;
  }
  .sm\:c-anime-duration-06 {
    animation-duration: 0.6s;
  }
  .sm\:c-anime-duration-08 {
    animation-duration: 0.8s;
  }
  .sm\:c-anime-duration-10 {
    animation-duration: 1s;
  }
  .sm\:c-anime-duration-12 {
    animation-duration: 1.2s;
  }
  .sm\:c-anime-duration-14 {
    animation-duration: 1.4s;
  }
  .sm\:c-anime-duration-16 {
    animation-duration: 1.6s;
  }
  .sm\:c-anime-duration-18 {
    animation-duration: 1.8s;
  }
  .sm\:c-anime-duration-20 {
    animation-duration: 2s;
  }
  .sm\:c-anime-delay-none {
    animation-delay: 0;
  }
  .sm\:c-anime-delay-02 {
    animation-delay: 0.2s;
  }
  .sm\:c-anime-delay-04 {
    animation-delay: 0.4s;
  }
  .sm\:c-anime-delay-06 {
    animation-delay: 0.6s;
  }
  .sm\:c-anime-delay-08 {
    animation-delay: 0.8s;
  }
  .sm\:c-anime-delay-10 {
    animation-delay: 1s;
  }
  .sm\:c-anime-delay-12 {
    animation-delay: 1.2s;
  }
  .sm\:c-anime-delay-14 {
    animation-delay: 1.4s;
  }
  .sm\:c-anime-delay-16 {
    animation-delay: 1.6s;
  }
  .sm\:c-anime-delay-18 {
    animation-delay: 1.8s;
  }
  .sm\:c-anime-delay-20 {
    animation-delay: 2s;
  }
  .sm\:c-anime-delay-22 {
    animation-delay: 2.2s;
  }
  .sm\:c-anime-delay-24 {
    animation-delay: 2.4s;
  }
  .sm\:c-anime-delay-26 {
    animation-delay: 2.6s;
  }
  .sm\:c-anime-delay-28 {
    animation-delay: 2.8s;
  }
  .sm\:c-anime-delay-30 {
    animation-delay: 3s;
  }
}
.c-button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  width: 100%;
  padding: 1rem;
  font-weight: 400;
  font-size: 1rem;
  transition: all 0.2s ease;
}
@media screen and (max-width: 1024px) {
  .c-button {
    font-size: 0.875rem;
  }
}
@media screen and (max-width: 599px) {
  .c-button {
    font-size: 0.875rem;
  }
}
.c-button__icon {
  display: inline-block;
  width: 28px;
  transition: all 0.5s ease;
}
.c-button--01 {
  background-color: #0095D9;
  border-radius: 2px;
  color: #ffffff;
  z-index: 0;
}
.c-button--01::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #F0584A;
  transition: transform 0.6s cubic-bezier(0.8, 0, 0.2, 1) 0s;
  transform: scale(0, 1);
  transform-origin: right top;
  content: "";
  z-index: 1;
}
.c-button--01 span {
  position: relative;
  z-index: 2;
}
@media (hover: hover) {
  .c-button--01:hover::before, .c-button--01:focus::before {
    transform-origin: left top;
    transform: scale(1, 1);
  }
}

.c-col {
  display: flex;
  flex-wrap: wrap;
}

.c-col__auto {
  flex: 0 1 auto;
}
.c-col__fixed {
  flex: 0 0 auto;
}
.c-col__1 {
  width: 8.33%;
}
.c-col__2 {
  width: 16.66%;
}
.c-col__3 {
  width: 25%;
}
.c-col__4 {
  width: 33.33%;
}
.c-col__5 {
  width: 41.66%;
}
.c-col__6 {
  width: 50%;
}
.c-col__7 {
  width: 58.33%;
}
.c-col__8 {
  width: 66.66%;
}
.c-col__9 {
  width: 75%;
}
.c-col__10 {
  width: 83.33%;
}
.c-col__11 {
  width: 91.66%;
}
.c-col__12 {
  width: 100%;
}
.c-col__1of5 {
  width: 20%;
}
.c-col__1of7 {
  width: 14.285%;
}

@media screen and (max-width: 1024px) {
  .md\:c-col__auto {
    flex: 0 1 auto;
  }
  .md\:c-col__fixed {
    flex: 0 0 auto;
  }
  .md\:c-col__1 {
    width: 8.33%;
  }
  .md\:c-col__2 {
    width: 16.66%;
  }
  .md\:c-col__3 {
    width: 25%;
  }
  .md\:c-col__4 {
    width: 33.33%;
  }
  .md\:c-col__5 {
    width: 41.66%;
  }
  .md\:c-col__6 {
    width: 50%;
  }
  .md\:c-col__7 {
    width: 58.33%;
  }
  .md\:c-col__8 {
    width: 66.66%;
  }
  .md\:c-col__9 {
    width: 75%;
  }
  .md\:c-col__10 {
    width: 83.33%;
  }
  .md\:c-col__11 {
    width: 91.66%;
  }
  .md\:c-col__12 {
    width: 100%;
  }
  .md\:c-col__1of5 {
    width: 20%;
  }
  .md\:c-col__1of7 {
    width: 14.285%;
  }
}
@media screen and (max-width: 599px) {
  .sm\:c-col__auto {
    flex: 0 1 auto;
  }
  .sm\:c-col__fixed {
    flex: 0 0 auto;
  }
  .sm\:c-col__1 {
    width: 8.33%;
  }
  .sm\:c-col__2 {
    width: 16.66%;
  }
  .sm\:c-col__3 {
    width: 25%;
  }
  .sm\:c-col__4 {
    width: 33.33%;
  }
  .sm\:c-col__5 {
    width: 41.66%;
  }
  .sm\:c-col__6 {
    width: 50%;
  }
  .sm\:c-col__7 {
    width: 58.33%;
  }
  .sm\:c-col__8 {
    width: 66.66%;
  }
  .sm\:c-col__9 {
    width: 75%;
  }
  .sm\:c-col__10 {
    width: 83.33%;
  }
  .sm\:c-col__11 {
    width: 91.66%;
  }
  .sm\:c-col__12 {
    width: 100%;
  }
  .sm\:c-col__1of5 {
    width: 20%;
  }
  .sm\:c-col__1of7 {
    width: 14.285%;
  }
}
.c-container {
  max-width: 880px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
.c-container--sm {
  max-width: 620px;
}

.c-frame {
  overflow: hidden;
  position: relative;
  display: block;
  width: 100%;
  height: auto;
}
.c-frame > * {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.c-frame--1to1 {
  padding-top: 100%;
}
.c-frame--4to3 {
  padding-top: 75%;
}
.c-frame--3to2 {
  padding-top: 66.6666%;
}
.c-frame--16to9 {
  padding-top: 56.25%;
}
.c-frame--2to1 {
  padding-top: 50%;
}
.c-frame--12to5 {
  padding-top: 41.6666%;
}

.c-gutter--none {
  margin: -0;
}
.c-gutter--none > * {
  padding: 0;
}

.c-gutter--3xs {
  margin: -0.125rem;
}
.c-gutter--3xs > * {
  padding: 0.125rem;
}

.c-gutter--2xs {
  margin: -0.25rem;
}
.c-gutter--2xs > * {
  padding: 0.25rem;
}

.c-gutter--xs {
  margin: -0.5rem;
}
.c-gutter--xs > * {
  padding: 0.5rem;
}

.c-gutter--sm {
  margin: -1rem;
}
.c-gutter--sm > * {
  padding: 1rem;
}

.c-gutter--md {
  margin: -1.5rem;
}
.c-gutter--md > * {
  padding: 1.5rem;
}

.c-gutter--lg {
  margin: -2rem;
}
.c-gutter--lg > * {
  padding: 2rem;
}

.c-gutter--xl {
  margin: -3.5rem;
}
.c-gutter--xl > * {
  padding: 3.5rem;
}

.c-gutter--2xl {
  margin: -6rem;
}
.c-gutter--2xl > * {
  padding: 6rem;
}

.c-gutter--3xl {
  margin: -9rem;
}
.c-gutter--3xl > * {
  padding: 9rem;
}

@media screen and (max-width: 1024px) {
  .md\:c-gutter--none {
    margin: -0;
  }
  .md\:c-gutter--none > * {
    padding: 0;
  }
  .md\:c-gutter--3xs {
    margin: -0.125rem;
  }
  .md\:c-gutter--3xs > * {
    padding: 0.125rem;
  }
  .md\:c-gutter--2xs {
    margin: -0.25rem;
  }
  .md\:c-gutter--2xs > * {
    padding: 0.25rem;
  }
  .md\:c-gutter--xs {
    margin: -0.5rem;
  }
  .md\:c-gutter--xs > * {
    padding: 0.5rem;
  }
  .md\:c-gutter--sm {
    margin: -1rem;
  }
  .md\:c-gutter--sm > * {
    padding: 1rem;
  }
  .md\:c-gutter--md {
    margin: -1.5rem;
  }
  .md\:c-gutter--md > * {
    padding: 1.5rem;
  }
  .md\:c-gutter--lg {
    margin: -2rem;
  }
  .md\:c-gutter--lg > * {
    padding: 2rem;
  }
  .md\:c-gutter--xl {
    margin: -3.5rem;
  }
  .md\:c-gutter--xl > * {
    padding: 3.5rem;
  }
  .md\:c-gutter--2xl {
    margin: -6rem;
  }
  .md\:c-gutter--2xl > * {
    padding: 6rem;
  }
  .md\:c-gutter--3xl {
    margin: -9rem;
  }
  .md\:c-gutter--3xl > * {
    padding: 9rem;
  }
}
@media screen and (max-width: 599px) {
  .sm\:c-gutter--none {
    margin: -0;
  }
  .sm\:c-gutter--none > * {
    padding: 0;
  }
  .sm\:c-gutter--3xs {
    margin: -0.125rem;
  }
  .sm\:c-gutter--3xs > * {
    padding: 0.125rem;
  }
  .sm\:c-gutter--2xs {
    margin: -0.25rem;
  }
  .sm\:c-gutter--2xs > * {
    padding: 0.25rem;
  }
  .sm\:c-gutter--xs {
    margin: -0.5rem;
  }
  .sm\:c-gutter--xs > * {
    padding: 0.5rem;
  }
  .sm\:c-gutter--sm {
    margin: -1rem;
  }
  .sm\:c-gutter--sm > * {
    padding: 1rem;
  }
  .sm\:c-gutter--md {
    margin: -1.5rem;
  }
  .sm\:c-gutter--md > * {
    padding: 1.5rem;
  }
  .sm\:c-gutter--lg {
    margin: -2rem;
  }
  .sm\:c-gutter--lg > * {
    padding: 2rem;
  }
  .sm\:c-gutter--xl {
    margin: -3.5rem;
  }
  .sm\:c-gutter--xl > * {
    padding: 3.5rem;
  }
  .sm\:c-gutter--2xl {
    margin: -6rem;
  }
  .sm\:c-gutter--2xl > * {
    padding: 6rem;
  }
  .sm\:c-gutter--3xl {
    margin: -9rem;
  }
  .sm\:c-gutter--3xl > * {
    padding: 9rem;
  }
}
.c-hover {
  opacity: 1;
  transition: all 0.2s ease;
}
@media (hover: hover) {
  .c-hover:hover {
    opacity: 0.7;
  }
}

.c-hover--remove {
  opacity: 0.5;
  pointer-events: none;
}

.c-hover--underline {
  text-decoration: underline;
  opacity: 1;
  transition: all 0.2s ease;
}
@media (hover: hover) {
  .c-hover--underline:hover {
    text-decoration: none;
    opacity: 0.7;
  }
}

.c-hover--dark {
  z-index: 0;
  overflow: hidden;
  position: relative;
  display: block;
}
.c-hover--dark::before {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #000000;
  mix-blend-mode: multiply;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
@media (hover: hover) {
  .c-hover--dark:hover::before {
    opacity: 0.3;
  }
}

.c-hover--light {
  z-index: 0;
  overflow: hidden;
  position: relative;
  display: block;
}
.c-hover--light::before {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #000000;
  mix-blend-mode: multiply;
  opacity: 0.3;
  transition: all 0.2s ease-in-out;
}
@media (hover: hover) {
  .c-hover--light:hover::before {
    opacity: 0;
  }
}

.c-hover--grayscale {
  overflow: hidden;
  display: block;
}
.c-hover--grayscale > * {
  width: 100%;
  height: auto;
  transition: all 0.2s ease-in-out;
}
@media (hover: hover) {
  .c-hover--grayscale:hover > * {
    filter: grayscale(100%);
  }
}

.c-hover--zoom {
  overflow: hidden;
  display: block;
}
.c-hover--zoom > * {
  width: 100%;
  height: auto;
  transition: 0.9s cubic-bezier(0, 0, 0.3, 1);
}
@media (hover: hover) {
  .c-hover--zoom:hover > * {
    transform: scale(1.1);
  }
}

.c-icon {
  display: inline-block;
}
.c-icon--blank {
  width: 16px;
}
.c-icon--mail {
  width: 26px;
}
.c-icon--arrow-right {
  border-style: solid;
  border-top-width: 1.5px;
  border-right-width: 1.5px;
  width: 6px;
  height: 6px;
  transform: rotate(45deg);
}

.c-pillarbox {
  margin-left: auto;
  margin-right: auto;
  padding-right: 5%;
  padding-left: 5%;
}
.c-pillarbox--sm {
  padding-right: 2.5%;
  padding-left: 2.5%;
}
.c-pillarbox--lg {
  padding-right: 10%;
  padding-left: 10%;
}

@media screen and (max-width: 1024px) {
  .md\:c-pillarbox {
    margin-left: auto;
    margin-right: auto;
    padding-right: 5%;
    padding-left: 5%;
  }
  .md\:c-pillarbox--sm {
    padding-right: 2.5%;
    padding-left: 2.5%;
  }
  .md\:c-pillarbox--lg {
    padding-right: 10%;
    padding-left: 10%;
  }
}
@media screen and (max-width: 599px) {
  .sm\:c-pillarbox {
    margin-left: auto;
    margin-right: auto;
    padding-right: 5%;
    padding-left: 5%;
  }
  .sm\:c-pillarbox--sm {
    padding-right: 2.5%;
    padding-left: 2.5%;
  }
  .sm\:c-pillarbox--lg {
    padding-right: 10%;
    padding-left: 10%;
  }
}
.c-section {
  display: block;
  width: 100%;
}

.c-section--none {
  padding-top: 0;
  padding-bottom: 0;
}

.c-section--3xs {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.c-section--2xs {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.c-section--xs {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.c-section--sm {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.c-section--md {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.c-section--lg {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.c-section--xl {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.c-section--2xl {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.c-section--3xl {
  padding-top: 9rem;
  padding-bottom: 9rem;
}

@media screen and (max-width: 1024px) {
  .md\:c-section--none {
    padding-top: 0;
    padding-bottom: 0;
  }
  .md\:c-section--3xs {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .md\:c-section--2xs {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .md\:c-section--xs {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .md\:c-section--sm {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .md\:c-section--md {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .md\:c-section--lg {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .md\:c-section--xl {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .md\:c-section--2xl {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .md\:c-section--3xl {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
}
@media screen and (max-width: 599px) {
  .sm\:c-section--none {
    padding-top: 0;
    padding-bottom: 0;
  }
  .sm\:c-section--3xs {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .sm\:c-section--2xs {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .sm\:c-section--xs {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .sm\:c-section--sm {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .sm\:c-section--md {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .sm\:c-section--lg {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .sm\:c-section--xl {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .sm\:c-section--2xl {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .sm\:c-section--3xl {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
}
.c-table {
  display: table;
  width: 100%;
  table-layout: auto;
}
.c-table th,
.c-table td {
  padding-top: 1em;
  padding-right: 1em;
  padding-bottom: 1em;
  padding-left: 1em;
  border: solid 1px #000000;
}
.c-table th {
  text-align: center;
}

.c-table--fixed {
  table-layout: fixed;
}

.c-typography--2xs {
  font-size: 0.625rem;
}
@media screen and (max-width: 1024px) {
  .c-typography--2xs {
    font-size: 0.5rem;
  }
}
@media screen and (max-width: 599px) {
  .c-typography--2xs {
    font-size: 0.5rem;
  }
}

@media screen and (max-width: 1024px) {
  .md\:c-typography--2xs {
    font-size: 0.5rem;
  }
}
@media screen and (max-width: 599px) {
  .md\:c-typography--2xs {
    font-size: 0.5rem;
  }
}

@media screen and (max-width: 599px) {
  .sm\:c-typography--2xs {
    font-size: 0.5rem;
  }
}

.c-typography--xs {
  font-size: 0.75rem;
}
@media screen and (max-width: 1024px) {
  .c-typography--xs {
    font-size: 0.625rem;
  }
}
@media screen and (max-width: 599px) {
  .c-typography--xs {
    font-size: 0.625rem;
  }
}

@media screen and (max-width: 1024px) {
  .md\:c-typography--xs {
    font-size: 0.625rem;
  }
}
@media screen and (max-width: 599px) {
  .md\:c-typography--xs {
    font-size: 0.625rem;
  }
}

@media screen and (max-width: 599px) {
  .sm\:c-typography--xs {
    font-size: 0.625rem;
  }
}

.c-typography--sm {
  font-size: 0.875rem;
}
@media screen and (max-width: 1024px) {
  .c-typography--sm {
    font-size: 0.75rem;
  }
}
@media screen and (max-width: 599px) {
  .c-typography--sm {
    font-size: 0.75rem;
  }
}

@media screen and (max-width: 1024px) {
  .md\:c-typography--sm {
    font-size: 0.75rem;
  }
}
@media screen and (max-width: 599px) {
  .md\:c-typography--sm {
    font-size: 0.75rem;
  }
}

@media screen and (max-width: 599px) {
  .sm\:c-typography--sm {
    font-size: 0.75rem;
  }
}

.c-typography--normal {
  font-size: 1rem;
}
@media screen and (max-width: 1024px) {
  .c-typography--normal {
    font-size: 0.875rem;
  }
}
@media screen and (max-width: 599px) {
  .c-typography--normal {
    font-size: 0.875rem;
  }
}

@media screen and (max-width: 1024px) {
  .md\:c-typography--normal {
    font-size: 0.875rem;
  }
}
@media screen and (max-width: 599px) {
  .md\:c-typography--normal {
    font-size: 0.875rem;
  }
}

@media screen and (max-width: 599px) {
  .sm\:c-typography--normal {
    font-size: 0.875rem;
  }
}

.c-typography--lg {
  font-size: 1.125rem;
}
@media screen and (max-width: 1024px) {
  .c-typography--lg {
    font-size: 1rem;
  }
}
@media screen and (max-width: 599px) {
  .c-typography--lg {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1024px) {
  .md\:c-typography--lg {
    font-size: 1rem;
  }
}
@media screen and (max-width: 599px) {
  .md\:c-typography--lg {
    font-size: 1rem;
  }
}

@media screen and (max-width: 599px) {
  .sm\:c-typography--lg {
    font-size: 1rem;
  }
}

.c-typography--xl {
  font-size: 1.25rem;
}
@media screen and (max-width: 1024px) {
  .c-typography--xl {
    font-size: 1.125rem;
  }
}
@media screen and (max-width: 599px) {
  .c-typography--xl {
    font-size: 1.125rem;
  }
}

@media screen and (max-width: 1024px) {
  .md\:c-typography--xl {
    font-size: 1.125rem;
  }
}
@media screen and (max-width: 599px) {
  .md\:c-typography--xl {
    font-size: 1.125rem;
  }
}

@media screen and (max-width: 599px) {
  .sm\:c-typography--xl {
    font-size: 1.125rem;
  }
}

.c-typography--2xl {
  font-size: 1.5rem;
}
@media screen and (max-width: 1024px) {
  .c-typography--2xl {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 599px) {
  .c-typography--2xl {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 1024px) {
  .md\:c-typography--2xl {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 599px) {
  .md\:c-typography--2xl {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 599px) {
  .sm\:c-typography--2xl {
    font-size: 1.25rem;
  }
}

.c-typography--3xl {
  font-size: 2rem;
}
@media screen and (max-width: 1024px) {
  .c-typography--3xl {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 599px) {
  .c-typography--3xl {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 1024px) {
  .md\:c-typography--3xl {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 599px) {
  .md\:c-typography--3xl {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 599px) {
  .sm\:c-typography--3xl {
    font-size: 1.5rem;
  }
}

.c-typography--truncate {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.c-typography--truncate-2 {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.p-footer {
  background-color: rgba(0, 107, 180, 0.1);
}
.p-footer__contact {
  text-align: center;
}
.p-footer__contact__button {
  padding: 1.7rem;
  font-size: 18px;
  font-weight: 700;
}
.p-footer__link {
  margin-top: 3.5rem;
}
.p-footer__link__list__item {
  text-align: center;
}
.p-footer__link__card {
  display: inline-block;
  width: 100%;
  max-width: 280px;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.5s;
}
.p-footer__link__card:hover {
  opacity: 0.7;
}
.p-footer__link__card__logoarea {
  position: relative;
  width: 100%;
  height: 114px;
  background-color: #ffffff;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.p-footer__link__card__logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
}
.p-footer__link__card__textarea {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}
.p-footer__link__card__text {
  display: block;
  text-align: left;
  padding: 0.4rem 1rem;
  color: #ffffff;
  font-size: 14px;
}
.p-footer__link__card__text .c-icon--blank {
  width: 10px;
}
.p-footer__copyright {
  margin-top: 3.5rem;
  text-align: center;
  background-color: #006BB4;
  padding: 1rem;
}
.p-footer__copyright__text {
  letter-spacing: 0;
  color: #ffffff;
  font-size: 12px;
}
@media screen and (max-width: 599px) {
  .p-footer__copyright__text {
    font-size: 10px;
  }
}

.p-header {
  position: relative;
  width: 100vw;
  height: 100vh;
  min-height: 500px;
  max-height: 800px;
  z-index: 900;
}
@media screen and (max-width: 599px) {
  .p-header {
    height: calc(var(--vh) * 100);
  }
}
.p-header__content {
  position: relative;
  width: 100%;
  height: calc(100% - 25px);
}
@media screen and (max-width: 599px) {
  .p-header__content {
    height: calc(100% - 200px);
  }
}
.p-header__content__grid {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 1024px) {
  .p-header__content__grid {
    flex-direction: column-reverse;
  }
}
.p-header__content__grid__item {
  position: relative;
}
.p-header__content__grid__item:first-of-type {
  background-color: #006BB4;
  width: 60%;
  height: 100%;
}
@media screen and (max-width: 1024px) {
  .p-header__content__grid__item:first-of-type {
    width: 100vw;
    height: 60%;
  }
}
.p-header__content__grid__item:last-of-type {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 900;
  width: 40%;
  height: 100%;
  background-color: rgba(0, 107, 180, 0.1);
}
@media screen and (max-width: 1024px) {
  .p-header__content__grid__item:last-of-type {
    width: 100vw;
    height: 40%;
  }
}
.p-header__catch {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  white-space: nowrap;
  font-weight: 300;
}
@media screen and (max-width: 1024px) {
  .p-header__catch {
    text-align: center;
  }
}
.p-header__catch__main {
  line-height: 1.2;
  letter-spacing: 0.8rem;
  font-size: 78px;
}
@media screen and (max-width: 1024px) {
  .p-header__catch__main {
    font-size: 52px;
  }
}
@media screen and (max-width: 599px) {
  .p-header__catch__main {
    font-size: 38px;
    letter-spacing: 0.4rem;
  }
}
.p-header__catch__sub {
  margin-top: 1rem;
  letter-spacing: 0.2rem;
  font-size: 20px;
}
@media screen and (max-width: 1024px) {
  .p-header__catch__sub {
    font-size: 16px;
  }
}
@media screen and (max-width: 599px) {
  .p-header__catch__sub {
    font-size: 12px;
    letter-spacing: 0.1rem;
  }
}
.p-header__logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 260px;
  margin-right: auto;
  margin-left: auto;
}
@media screen and (max-width: 1024px) {
  .p-header__logo {
    width: 90%;
    max-width: 260px;
  }
}
.p-header__nav {
  position: absolute;
  left: 50%;
  bottom: -25px;
  transform: translate(-50%, 0);
  width: 90%;
  max-width: 522px;
  z-index: 1000;
}
@media screen and (max-width: 599px) {
  .p-header__nav {
    position: static;
    left: auto;
    bottom: auto;
    transform: translate(0, 0);
    margin-top: 1.5rem;
    margin-right: auto;
    margin-left: auto;
  }
}
.p-header__nav__list {
  justify-content: center;
}
.p-header__nav__list__item {
  text-align: center;
}
.p-header__nav__button {
  max-width: 250px;
  letter-spacing: 0.2rem;
  font-size: 1.25rem;
}
@media screen and (max-width: 1024px) {
  .p-header__nav__button {
    font-size: 1.125rem;
  }
}
@media screen and (max-width: 599px) {
  .p-header__nav__button {
    font-size: 1.125rem;
  }
}

.p-home__heading {
  margin-bottom: 50px;
  text-align: center;
}
.p-home__heading__ja {
  letter-spacing: 0.2rem;
  font-weight: 400;
  color: #006BB4;
  font-size: 30px;
}
@media screen and (max-width: 1024px) {
  .p-home__heading__ja {
    font-size: 24px;
  }
}
.p-home__heading__en {
  display: inline-block;
  width: auto;
  height: 12px;
  margin-right: auto;
  margin-left: auto;
}
.p-home__heading__en img {
  width: auto;
  height: 100%;
}
.p-home__business {
  position: relative;
}
.p-home__business::before {
  position: absolute;
  top: -25px;
  left: 0;
  width: 100vw;
  height: calc(100% + 25px);
  background-image: url("../images/bg_plaid.png");
  background-repeat: repeat;
  z-index: -1;
  content: "";
}
@media screen and (max-width: 599px) {
  .p-home__business::before {
    top: -200px;
    height: calc(100% + 200px);
  }
}
.p-home__business__card {
  padding: 70px 60px;
  background-color: #ffffff;
  border-style: solid;
  border-width: 1px;
  border-color: #006BB4;
  border-radius: 10px;
}
@media screen and (max-width: 1024px) {
  .p-home__business__card {
    padding: 10%;
  }
}
.p-home__business__card__title {
  position: relative;
  margin-bottom: 40px;
  padding-bottom: 20px;
  letter-spacing: 0.2rem;
  font-weight: 400;
  font-size: 30px;
  color: #006BB4;
}
@media screen and (max-width: 1024px) {
  .p-home__business__card__title {
    font-size: 24px;
  }
}
@media screen and (max-width: 599px) {
  .p-home__business__card__title {
    font-size: 18px;
  }
}
.p-home__business__card__title::after {
  position: absolute;
  bottom: 0;
  left: 0;
  display: inline-block;
  width: 68px;
  height: 1px;
  background-color: #F0584A;
  content: "";
}
.p-home__business__card__description {
  min-height: 110px;
  text-align: justify;
}
@media screen and (max-width: 599px) {
  .p-home__business__card__description {
    min-height: auto;
  }
}
.p-home__business__card__link {
  max-width: 250px;
}
.p-home__business__card__link__button {
  justify-content: left;
  margin-top: 1rem;
  border-radius: 2px;
  font-weight: 300;
  color: #ffffff;
}
@media (hover: hover) {
  .p-home__business__card__link__button:hover, .p-home__business__card__link__button:focus {
    opacity: 0.7;
  }
}
.p-home__company {
  background-color: rgba(0, 107, 180, 0.1);
}
.p-home__company__table tr {
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.5);
  border-bottom-width: 0.5px;
}
.p-home__company__table tr:first-of-type {
  border-top-width: 0.5px;
}
.p-home__company__table th {
  width: 140px;
  border-style: solid;
  border-color: #006BB4;
  border-bottom-width: 3px;
  font-weight: 500;
}
@media screen and (max-width: 599px) {
  .p-home__company__table th {
    width: 120px;
  }
}
.p-home__company__table th:first-of-type {
  border-top-width: 3px;
}
.p-home__company__table th, .p-home__company__table td {
  padding: 24px;
}
@media screen and (max-width: 599px) {
  .p-home__company__table th, .p-home__company__table td {
    padding: 12px;
  }
}
.p-home__company__table td {
  text-align: justify;
}
.p-home__access iframe {
  width: 100%;
  height: 468px;
}
@use "../../global" as *;

@include responsive using ($prefix) {
	@each $name, $value in $spacing {
		.#{$prefix}c-gutter--#{$name} {
			margin: -#{$value};
			& > * {
				padding: $value;
			}
		}
	}
}
@use "../../global" as *;

@each $name, $value in $font-size {
  .c-typography--#{$name} {
    @include font-size($name);
  }
  .md\:c-typography--#{$name} {
    @include font-size(null, $name);
  }
  .sm\:c-typography--#{$name} {
    @include font-size(null, null, $name);
  }
}

.c-typography--truncate {
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
  &-2 {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
@use "../../global" as *;

.c-table {
  display: table;
  width: 100%;
  table-layout: auto;
  th,
  td {
    padding-top: 1em;
    padding-right: 1em;
    padding-bottom: 1em;
    padding-left: 1em;
    border: solid 1px #000000;
  }
  th {
    text-align: center;
  }
}

.c-table--fixed {
  table-layout: fixed;
}
@use "../../global" as *;

.c-icon {
  display: inline-block;
  &--blank {
    width: 16px;
  }
  &--mail {
    width: 26px;
  }
  &--arrow-right{
    border-style: solid;
    border-top-width: 1.5px;
    border-right-width: 1.5px;
    width:6px;
    height:6px;
    transform: rotate(45deg);
  }
}
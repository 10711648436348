@use "../../global" as *;

@keyframes fade-in{
    0% {
        display: none;
        opacity: 0;
    }
    1% {
        display: block;
    }
    100% {
        opacity: 1;
    }
}  
@keyframes fade-up {
    0% {
        display: none;
        opacity: 0;
        transform: translateY(100px);
    }
    1% {
        display: block;
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes fade-down {
    0% {
        display: none;
        opacity: 0;
        transform: translateY(-100px);
    }
    1% {
        display: block;
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes fade-right {
    0% {
        display: none;
        opacity: 0;
        transform: translateX(100px);
    }
    1% {
        display: block;
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
@keyframes fade-left {
    0% {
        display: none;
        opacity: 0;
        transform: translateX(-100px);
    }
    1% {
        display: block;
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
.c-fade-in,
.c-fade-in-first,
.c-fade-up,
.c-fade-up-first,
.c-fade-down,
.c-fade-down-first,
.c-fade-right,
.c-fade-right-first,
.c-fade-left,
.c-fade-left-first{
  opacity: 0;
}
.c-fade-in.is-show,
.c-fade-in-first,
.c-fade-up.is-show,
.c-fade-up-first,
.c-fade-down.is-show,
.c-fade-down-first,
.c-fade-right.is-show,
.c-fade-right-first,
.c-fade-left.is-show,
.c-fade-left-first{
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
}
.c-fade-in.is-show,
.c-fade-up.is-show,
.c-fade-down.is-show,
.c-fade-right.is-show,
.c-fade-left.is-show{
    animation-duration: .4s;
}
.c-fade-in-first{
    animation-name:fade-in;
    animation-duration: 1s;
}
.c-fade-up-first{
    animation-name: fade-up;
}
.c-fade-down-first{
    animation-name: fade-down;
}
.c-fade-right-first{
    animation-name: fade-right;
}
.c-fade-left-first{
    animation-name: fade-left;
}

.c-fade-in.is-show{
    animation-name:fade-in;
}
.c-fade-up.is-show{
    animation-name:fade-up;
}
.c-fade-right.is-show{
    animation-name:fade-right;
}
.c-fade-left.is-show{
    animation-name:fade-left;
}
.c-fade-down.is-show{
    animation-name: fade-down;
}

@include responsive using ($prefix) {
    .#{$prefix}c-anime-duration-02{
        animation-duration: 0.2s;
    }
    .#{$prefix}c-anime-duration-04{
        animation-duration: 0.4s;
    }
    .#{$prefix}c-anime-duration-06{
        animation-duration: 0.6s;
    }
    .#{$prefix}c-anime-duration-08{
        animation-duration: 0.8s;
    }
    .#{$prefix}c-anime-duration-10{
        animation-duration: 1s;
    }
    .#{$prefix}c-anime-duration-12{
        animation-duration: 1.2s;
    }
    .#{$prefix}c-anime-duration-14{
        animation-duration: 1.4s;
    }
    .#{$prefix}c-anime-duration-16{
        animation-duration: 1.6s;
    }
    .#{$prefix}c-anime-duration-18{
        animation-duration: 1.8s;
    }
    .#{$prefix}c-anime-duration-20{
        animation-duration: 2s;
    }
    .#{$prefix}c-anime-delay-none{
        animation-delay: 0;
    }
    .#{$prefix}c-anime-delay-02{
        animation-delay: 0.2s;
    }
    .#{$prefix}c-anime-delay-04{
        animation-delay: 0.4s;
    }
    .#{$prefix}c-anime-delay-06{
        animation-delay: 0.6s;
    }
    .#{$prefix}c-anime-delay-08{
        animation-delay: 0.8s;
    }
    .#{$prefix}c-anime-delay-10{
        animation-delay: 1s;
    }
    .#{$prefix}c-anime-delay-12{
        animation-delay: 1.2s;
    }
    .#{$prefix}c-anime-delay-14{
        animation-delay: 1.4s;
    }
    .#{$prefix}c-anime-delay-16{
        animation-delay: 1.6s;
    }
    .#{$prefix}c-anime-delay-18{
        animation-delay: 1.8s;
    }
    .#{$prefix}c-anime-delay-20{
        animation-delay: 2s;
    }
    .#{$prefix}c-anime-delay-22{
        animation-delay: 2.2s;
    }
    .#{$prefix}c-anime-delay-24{
        animation-delay: 2.4s;
    }
    .#{$prefix}c-anime-delay-26{
        animation-delay: 2.6s;
    }
    .#{$prefix}c-anime-delay-28{
        animation-delay: 2.8s;
    }
    .#{$prefix}c-anime-delay-30{
        animation-delay: 3s;
    }
}
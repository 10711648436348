@use "../../global/" as *;

$block: '.p-footer';

#{$block} {
  background-color: color('paleblue');
  &__contact {
    text-align: center;
    &__button {
      padding:1.7rem;
      font-size: 18px;
      font-weight: 700;
    }
  }
  &__link {
    margin-top: spacing(xl);
    &__list{
      &__item {
        text-align: center;
      }
    }
    &__card {
      display: inline-block;
      width: 100%;
      max-width: 280px;
      margin-left: auto;
      margin-right:auto;
      transition: all 0.5s;
      &:hover {
        opacity: 0.7;
      }
      &__logoarea {
        position: relative;
        width: 100%;
        height: 114px;
        background-color: color('white');
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
      } 
      &__logo {
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        width: 80%;
      }
      &__textarea {
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
      }
      &__text {
        display: block;
        text-align: left;
        padding: 0.4rem 1rem;
        color:color('white');
        font-size: 14px;
        & .c-icon--blank {
          width:10px;
        }
      }
    }
  }
  &__copyright {
    margin-top: spacing(xl);
    text-align: center;
    background-color: color('blue');
    padding:1rem;
    &__text{
      letter-spacing: 0;
      color:color('white');
      font-size: 12px;
      @include media(sm){
        font-size: 10px;
      }
    }
  }
}
@use "../../global" as *;

@include responsive using ($prefix) {
  .#{$prefix}c-pillarbox {
  	margin-left: auto;
  	margin-right: auto;
    padding-right: 5%;
  	padding-left: 5%;
    &--sm {
      padding-right: 2.5%;
  	  padding-left: 2.5%;
    }
    &--lg {
      padding-right: 10%;
  	  padding-left: 10%;
    }
  }
}
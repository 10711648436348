@use "../../global/" as *;

$block: '.p-home';

#{$block} {
  &__heading {
    margin-bottom: 50px;
    text-align: center;
    &__ja{
      letter-spacing: 0.2rem;
      font-weight: 400;
      color:color('blue');
      font-size: 30px;
      @include media(md){
        font-size: 24px;
      }
    }
    &__en {
      display: inline-block;
      width: auto;
      height: 12px;
      margin-right:auto;
      margin-left: auto;
      & img{
        width: auto;
        height: 100%;
      }
    }
  }
  &__business {
    position: relative;
    &::before {
      position: absolute;
      top:-25px;
      left:0;
      width:100vw;
      height:calc(100% + 25px);
      background-image: image('bg_plaid.png');
      background-repeat: repeat;  
      z-index: -1;
      content:"";
      @include media(sm){
        top:-200px;
        height:calc(100% + 200px);
      }
    }
    &__card {
      padding:70px 60px;
      background-color: color('white');
      border-style: solid;
      border-width: 1px;
      border-color: color('blue');
      border-radius: 10px;
      @include media(md){
        padding:10%;
      }
      &__title {
        position: relative;
        margin-bottom: 40px;
        padding-bottom: 20px;
        letter-spacing: 0.2rem;
        font-weight: 400;
        font-size: 30px;
        color: color('blue');
        @include media(md){
          font-size: 24px;
        }
        @include media(sm){
          font-size: 18px;
        }
        &::after {
          position: absolute;
          bottom: 0;
          left: 0;
          display: inline-block;
          width: 68px;
          height: 1px;
          background-color: color('red');
          content:"";
        }
      }
      &__description {
        min-height: 110px;
        text-align: justify;
        @include media(sm) {
          min-height: auto;
        }
      }
      &__link {
        max-width: 250px;
        &__button {
          justify-content: left;
          margin-top:spacing(sm);
          border-radius: 2px;
          font-weight: 300;
          color: color('white');
          @include media(hover) {
            &:hover,
            &:focus{
              opacity: 0.7;
            }
          }      
        }
      }
    }
  }
  &__company {
    background-color: color('paleblue');
    &__table {
      & tr {
        border-style: solid;
        border-color: rgba(0,0,0,0.5);
        border-bottom-width: 0.5px;
        &:first-of-type {
          border-top-width: 0.5px;
        }
      }
      & th {
        width: 140px;
        border-style: solid;
        border-color: color('blue');
        border-bottom-width: 3px;
        font-weight: 500;
        @include media(sm){
          width: 120px;
        }
        &:first-of-type {
          border-top-width: 3px;
        }
      }
      & th,
      & td {
        padding:24px;
        @include media(sm){
          padding:12px;
        }
      }
      & td {
        text-align: justify;
      }
    }
  }
  &__access{
    & iframe {
      width: 100%;
      height: 468px;
    }
  }
}
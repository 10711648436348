@use "../../global" as *;

.c-frame {
  overflow: hidden;
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  & > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.c-frame {
  &--1to1 {
    padding-top: 100%;
  }
  &--4to3 {
    padding-top: 75%;
  } 
  &--3to2 {
    padding-top: 66.6666%;
  }
  &--16to9 {
    padding-top: 56.25%;
  }
  &--2to1 {
    padding-top: 50%;
  }
  &--12to5 {
    padding-top: 41.6666%;
  }
}
@use "../../global" as *;

.c-container {
	max-width: 880px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
  &--sm {
    max-width: 620px;
  }
}
@use "../../global/" as *;

$block: '.p-header';

#{$block} {
  position: relative;
  width: 100vw;
  height:100vh;
  min-height: 500px;
  max-height: 800px;
  z-index: 900;
  @include media(sm){
    height: calc(var(--vh) * 100);
  }
  &__content {
    position: relative;
    width: 100%;
    height: calc(100% - 25px);
    @include media(sm) {
      height: calc(100% - 200px);
    }
    &__grid {
      width: 100%;
      height: 100%;
      @include media(md){
        flex-direction: column-reverse;
      }
      &__item {
        position: relative;
        &:first-of-type{
          background-color: color('blue');
          width:60%;
          height:100%;
          @include media(md) {
            width: 100vw;
            height: 60%;
          }
        }
        &:last-of-type{
          position: absolute;
          top:0;
          right:0;
          z-index: 900;
          width: 40%;
          height: 100%;
          background-color: color('paleblue');
          @include media(md) {
            width: 100vw;
            height: 40%;
          }
        }
      }
    }
  }
  &__catch {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: color('white');
    white-space: nowrap;
    font-weight: 300;
    @include media(md) {
      text-align: center;
    }
    &__main {
      line-height: 1.2;
      letter-spacing: 0.8rem;
      font-size: 78px;
      @include media(md) {
        font-size: 52px;
      }
      @include media(sm) {
        font-size: 38px;
        letter-spacing: 0.4rem;
      }
    }
    &__sub {
      margin-top: 1rem;
      letter-spacing: 0.2rem;
      font-size: 20px;
      @include media(md) {
        font-size: 16px;
      }
      @include media(sm) {
        font-size: 12px;
        letter-spacing: 0.1rem;
      }
    }
  }
  &__logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 260px;
    margin-right: auto;
    margin-left: auto;
    @include media(md){
      width: 90%;
      max-width: 260px;
    }
  }
  &__nav {
    position: absolute;
    left: 50%;
    bottom: -25px;
    transform: translate(-50%,0);
    width: 90%;
    max-width: 522px;
    z-index: 1000;
    @include media(sm) {
      position: static;
      left: auto;
      bottom: auto;
      transform: translate(0,0);
      margin-top:spacing(md);
      margin-right: auto;
      margin-left: auto;
    }
    &__list {
      justify-content: center;
      &__item {
        text-align: center;
      }
    }
    &__button {
      max-width: 250px;
      letter-spacing: 0.2rem;
      @include font-size(xl);
    }
  }
}
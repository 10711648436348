@use "../../global" as *;

.c-hover {
	opacity: 1;
	transition: all 0.2s ease;
  @include media(hover) { 
    &:hover {
      opacity: 0.7;
    }
  }
}

.c-hover--remove {
  opacity: 0.5;
  pointer-events: none;
}

.c-hover--underline {
  text-decoration: underline;
  opacity: 1;
	transition: all 0.2s ease;
  @include media(hover) {
    &:hover {
      text-decoration: none;
      opacity: 0.7;
    }
  }
}

.c-hover--dark {
  z-index: 0;
	overflow: hidden;
	position: relative;
	display: block;
	&::before {
		z-index: 1;
    position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		height: 100%;
		content: '';
		background-color: #000000;
		mix-blend-mode: multiply;
		opacity: 0;
		transition: all 0.2s ease-in-out;
	}
  @include media(hover) {
    &:hover {
      &::before {
        opacity: 0.3;
      }
    }
	}
}

.c-hover--light {
  z-index: 0;
	overflow: hidden;
	position: relative;
	display: block;
	&::before {
		z-index: 1;
    position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		height: 100%;
		content: '';
		background-color: #000000;
		mix-blend-mode: multiply;
		opacity: 0.3;
		transition: all 0.2s ease-in-out;
	}
  @include media(hover) {
    &:hover {
		  &::before {
			  opacity: 0;
		  }
    }
	}
}

.c-hover--grayscale {
	overflow: hidden;
	display: block;
	& > * {
		width: 100%;
		height: auto;
		transition: all 0.2s ease-in-out;
	}
  @include media(hover) {
    &:hover {
	  	& > * {
	  		filter: grayscale(100%);
	  	}
	  }
  }
}

.c-hover--zoom {
	overflow: hidden;
  display: block;
	& > * {
		width: 100%;
		height: auto;
		transition: 0.9s cubic-bezier(0, 0, .3, 1);
	}
  @include media(hover) {
	  &:hover > * {
      transform: scale(1.1);
    }
	}
}
@use "../../global" as *;

.c-section {
	display: block;
	width: 100%;
}

@include responsive using ($prefix) {
	@each $name, $value in $spacing {
		.#{$prefix}c-section--#{$name} {
			padding-top: $value;
			padding-bottom: $value;
		}
	}
}
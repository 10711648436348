@use "../global" as *;

html {
	font-size: 16px;
}

body {
	letter-spacing: tracking(normal);
	line-height: leading(normal);
	font-family: 'Noto Sans JP', sans-serif;
	font-kerning: normal;
	font-feature-settings: 'palt';
	@include font-size(normal);
	font-weight: 300;
	color: #000000;
  background-color: #ffffff;
}

body.is-fixscroll{
	position: fixed;
	top: 0;
	left: 0;
	display: block;
	width: 100vw;
	height: 100vh;
	z-index: -999;
}

img,
object,
svg,
iframe {
  display: block;
	max-width: 100%;
	width: auto;
	height: auto;
	vertical-align: middle;
}

img[src*='.svg'] {
	width: 100%;
}

ul,
ol {
  list-style-position: inside;
}

::placeholder {
  color: #a0a0a0;
}
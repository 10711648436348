@use '../global' as *;

.l-main {
	position: relative;
	width: 100%;
	min-height: 100vh;
	margin-right: auto;
	margin-left: auto;
}

.l-main--noespacer {
  padding-bottom: 0;
	@include media(md) {
		padding-bottom: 0;
	}
}